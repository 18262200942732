<template>
  <Page :hasBg="false" class="download-contain">
    <div class="guide pa-16 font-14 fw-500" v-if="isWechat">
      <div class="triangle-up"></div>
      <div>1.点击右上角"..."</div>
      <div>2.再点击“在浏览器打开”<Icon name="liulanqi"></Icon></div>
      <div class="text-center mt-16">
        <Icon name="xiazai"></Icon>
        请在浏览器中下载
      </div>
    </div>
    <div class="down-load px-16 py-60 text-center d-flex justify-space-around align-center flex-column">
      <div class="d-flex justify-center align-center">
        <div class="mr-18">
          <img :src="imgUrl" alt="" />
        </div>
        <div>
          <div class="text_primary font-24 fw-500">网络货运司机</div>
          <div class="font-16 mt-14">让货运便捷，更高效</div>
        </div>
      </div>
      <div>
        <a :href="downloadUrl" v-if="!isiOS">
          <Button :disabled="isiOS" size="large" type="primary">
            点击下载
          </Button>
        </a>
        <div class="mt-16 text_secondary">
          {{ !isiOS ? '注意：Android版本支持5.0及以上系统' : '暂不支持ios下载，工程师正在开发中' }}
        </div>
      </div>
    </div>
  </Page>
</template>
<script>
import { defineComponent, onMounted, ref } from '@vue/composition-api';
import { apiGetNewAppVersionByWeb } from '@/api/mine';
import { Button } from 'vant';
export default defineComponent({
  components: {
    Button
  },
  setup() {
    onMounted(async () => {
      document.title = '司机APP下载';
      const res = await apiGetNewAppVersionByWeb({
        tenantId: window.__Dynamic_Config__.tenantId
      });
      downloadUrl.value = res.data.url;
    });
    const downloadUrl = ref();
    const isiOS = ref(!!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/));
    const imgUrl = ref();
    // const isWechat = ref(navigator.userAgent.toLowerCase().indexOf('micromessenger') !== -1);
    const isWechat = ref(navigator.userAgent.toLowerCase().match(/micromessenger/i) == 'micromessenger');
    return {
      downloadUrl,
      isiOS,
      imgUrl,
      isWechat
    };
  }
});
</script>
<style lang="less">
.download-contain {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: url('../../assets/downloadbg.png');
  .down-load {
    height: 5rem;
    a {
      color: white;
    }
    img {
      width: 0.58rem;
      height: 0.58rem;
      border-radius: 0.16rem;
      box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.05);
    }
  }
  .guide {
    position: absolute;
    width: 2rem;
    border-radius: 0.04rem;
    background: rgba(000, 000, 000, 0.7);
    right: 0.16rem;
    color: white;
    top: 0.2rem;
    border-top-right-radius: 0;
  }
  .triangle-up {
    width: 0;
    height: 0;
    position: absolute;
    right: 0;
    top: -0.1rem;
    border-left: 0.2rem solid transparent;
    border-bottom: 0.1rem solid rgba(000, 000, 000, 0.7);
  }
}
</style>
